<template>
  <div class="login-container">
    <div class="login-form">
      <div class="login-title">
        {{title}}<br> 
      </div>
      <div class="login-body">
        <div class="body-form">
          <div class="form-title">登录</div>
          <el-form class="form-input" autoComplete="on" :model="loginForm" :rules="loginRules" ref="loginForm" label-position="top" :hide-required-asterisk="true">            
            <el-form-item prop="username" class="input-content" label="用户名">
            <el-input  placeholder="请输入用户名" v-model="loginForm.username" name="username" type="text"> 
            	</el-input>
            </el-form-item>
            <el-form-item prop="password" class="input-content pwd-input" label="密码">
            <el-input  placeholder="请输入密码" type="password" v-model="loginForm.password" name="password"></el-input>
            </el-form-item>
            <div class="other-input">
            </div>
            <el-button type="primary" class="login-btn" :loading="loading" @click.native.prevent="login">登录</el-button>
          </el-form>
          <div class="footer">Copyright &copy;</div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
  import {login} from '@/api/getUcenterData'
  import CryptoJS from 'crypto-js/crypto-js'
  import cookies from '@/utils/cookies/index'
  import setting from '@/utils/config/index.js'
  export default {
    data () {
      return {
       loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', message: '请输入用户名'}],
        password: [{ required: true, trigger: 'blur', message: '请输入密码'}]
      },
      pwdType:'password',
      loading:false,
      remember:true,
      title:setting.info.LoginText,
      title1:""
    }
  },
  mounted () {
  },
  created () {
    //将版本换行
    var loginTextArr = setting.info.LoginText.split("（");
    this.title = loginTextArr[0];
    this.title1 ="（" + loginTextArr[1];
    var uid = cookies.get('uid');
    if(uid){
      this.$router.push({path:"/base/home/index"});
    }
    var _that = this;
    document.onkeydown = function(e){
      if(window.event.keyCode === 13){
        _that.login();
      }
    }
  },
  beforeDestroy () {
    document.onkeydown = undefined
  },
  methods:{

    // 加密
    login:function(){       
      let paraForm = Object.assign({}, this.loginForm);
			let para = this.encryptByDES(paraForm.username + "," + paraForm.password, "SSO2020@dh")
      this.$refs.loginForm.validate(valid => {
        if(valid){
          this.loading = true;
          login({loginPara:para}).then((res)=>{
            console.log();
             this.loading = false;

            if(res.resultCode === 1){
              this.$message({type: 'success',message: '登录成功'});
              this.$store.dispatch('dh/user/set',res.data);
            
              window.location.href = window.location.protocol + '//' + window.location.host + "/base/home/index";
           }else{
            this.$message({type: 'error',message: res.resultMsg});
          }
        }).catch(()=>{
           this.$message({type: 'error',message: '网络错误'});
           this.loading = false;
        })
        }  
      })
    },
    encryptByDES(message, key){  
				const keyHex = CryptoJS.enc.Utf8.parse(key);  
				const encrypted = CryptoJS.DES.encrypt(message, keyHex, {  
					mode: CryptoJS.mode.ECB,  
					padding: CryptoJS.pad.Pkcs7  
				});  
				return encrypted.toString();  
			},
    showPwd:function(){
      if (this.pwdType === 'password') {
        this.pwdType = ''
      } else {
        this.pwdType = 'password'
      }
    }
  }
}
</script>

<style lang="scss">
.login-container {
  position:relative;
  height:100vh;
  background:url('~@/modules/base/assets/img/qrcode01.png') no-repeat;
  background-size: 100% 100%;

  input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px #FFF inset !important;
      -webkit-text-fill-color: #525252 !important;
  }
  input {
    background: transparent;
    border: none;
    border-bottom:1px solid  #C9D2DB;
    -webkit-appearance: none;
    border-radius: 0px;
    padding:0px;
    color: #525252;
    height: 47px;
  }

  .el-form--label-top .el-form-item__label{
    padding:0px;
    line-height: 1.5rem;
    font-size: 0.6rem;
  }
  
  
  .login-form{
    width:32.5rem;
    height:32.25rem;
    position:absolute;
    left:50%;
    top: 4.8rem;
    margin-left: -16.25rem;
  }

  .login-title{
    font-family: "Comic Sans MS", cursive, sans-serif;
    text-align: center;
    font-size: 36px;
    color:#fff;    
  }

  .login-body{
    margin: 5% 0;
    width:100%;
    // background-color: #19BF7D;
    box-shadow:0 2px 12px 0 rgba(0,0,0,.3);
    display:flex;
    .body-form{      
      border-radius: 10px;
      width:100%;
      height:440px;
      background-color:#FFFFFF;
    }

    .form-title{
      height:4rem;
      line-height:4rem;
      padding-left: 2.65rem;
      color:#21293B;
      font-size:1rem;
      font-weight:500;
    }

    .form-input{
      padding:0 2.65rem;


      .other-input{
        height:0.5rem;
        line-height:0.5rem;
        display: -webkit-flex;
        display: fiex;
        justify-content: space-between;
        .remember-me{
          display:inline-block;
          height:30px; 
          line-height:30px;         
          .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
            background-color:#67C23A;
            border-color:#67C23A;
          }
          .el-checkbox__label{
            color: #525252;
          }
        }
      }
    }

    .login-btn{
      width:5.1rem;  
      // height:  1.75rem;
      // border-radius:0.875rem; 
      // font-size: 0.8rem;
      // padding:0;
    }

    .forget-pwd{
      cursor: pointer;
    }

    .footer{
      text-align: center;
      font-size: 12px;
      margin-top: 3.5rem;
      color: #C0C4CC;
    }
  }
}
</style>
